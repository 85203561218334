import { useEffect, useState } from "react";
import { useDebounce } from "react-use";
import classes from "./ArchivedHeader.module.scss";
import { ArchivedSearch } from "./ArchivedSearch";
import { ArchivedOrdering } from "./ArchivedOrdering";
import { Form } from "react-bootstrap";
import { OrderBy, OrderDirection } from "../../contracts";

export interface ArchivedHeaderProps {
  /**
   * The current search query is controlled.
   */
  query: string;

  /**
   * Which date field to order the links by.
   */
  orderBy: OrderBy;

  /**
   * The direction to order the links by.
   */
  orderDirection: OrderDirection;

  /**
   * Callback for emitting a new search query.
   * @param query New search query.
   */
  onSearch: (query: string) => void;

  /**
   * Callback for emitting a new field to order by.
   * @param orderBy New field to order by.
   */
  onOrderBy: (orderBy: OrderBy) => void;

  /**
   * Callback for emitting a new ordering direction.
   * @param orderDirection New ordering direction.
   */
  onOrderDirection: (orderDirection: OrderDirection) => void;
}

/**
 * Search bar for filtering the archived links.
 */
export const ArchivedHeader = ({
  query,
  orderBy,
  orderDirection,
  onSearch,
  onOrderBy,
  onOrderDirection,
}: ArchivedHeaderProps) => {
  const [inputQuery, setInputQuery] = useState("");

  // Propagate the new query after the debounce period, to avoid too much processing.
  useDebounce(
    () => {
      onSearch(inputQuery);
    },
    350,
    [inputQuery]
  );

  // Sync internal query with the external query. They are separate to support debouncing.
  useEffect(() => {
    setInputQuery(query);
  }, [query]);

  return (
    <Form className={classes["archived-header"]}>
      <ArchivedSearch query={query} onSearch={onSearch} />
      <ArchivedOrdering
        orderBy={orderBy}
        orderDirection={orderDirection}
        onOrderBy={onOrderBy}
        onOrderDirection={onOrderDirection}
      />
    </Form>
  );
};
