import {
  DndContext,
  DragEndEvent,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { useErrorBoundary } from "react-error-boundary";

import { useLinks } from "../../hooks";
import { SortableLink } from "./SortableLink";

/**
 * Display a sortable list of links for the editor.
 */
export const SortableLinkList = () => {
  const { parentLinks: links, reorderLinks } = useLinks();
  const sensors = useSensors(useSensor(PointerSensor));
  const { showBoundary } = useErrorBoundary();

  const linkIds = links.map((link) => link.id);

  const saveNewOrder = async (newOrder: string[]) => {
    try {
      await reorderLinks(newOrder);
    } catch (error) {
      showBoundary(error);
    }
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (active.id && over?.id && active.id !== over.id) {
      const oldIndex = linkIds.indexOf(active.id as string);
      const newIndex = linkIds.indexOf(over.id as string);
      const newOrder = arrayMove(linkIds, oldIndex, newIndex);
      saveNewOrder(newOrder);
    }
  };

  return (
    <DndContext
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      sensors={sensors}
    >
      <SortableContext items={linkIds} strategy={verticalListSortingStrategy}>
        <div className="list-group">
          {links.map(({ id }, index) => (
            <SortableLink
              id={id}
              key={id}
              number={index + 1}
              firstInList={index === 0}
              lastInList={index === links.length - 1}
            />
          ))}
        </div>
      </SortableContext>
    </DndContext>
  );
};
