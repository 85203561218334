import { Button } from "react-bootstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { prettyDate } from "../../helpers";
import classes from "./EncryptionKeyDisplay.module.scss";

export interface EncryptionKeyDisplayProps {
  /**
   * The exported and stringified key to display.
   */
  encryptionKey: string;

  /**
   * When the key was created/imported.
   */
  addedOn: Date;
}

/**
 * Display information about a currently set encryption key, including allowing
 * the user to copy it to their clipboard.
 */
export const EncryptionKeyDisplay = ({
  encryptionKey,
  addedOn,
}: EncryptionKeyDisplayProps) => {
  return (
    <div>
      <p className={classes["key-display"]}>
        <code>{encryptionKey}</code>
      </p>
      <p className="text-secondary">
        <small>Added on {prettyDate(addedOn)}</small>
      </p>
      <p className="mb-0">
        <CopyToClipboard text={encryptionKey}>
          <Button variant="outline-secondary">Copy Key</Button>
        </CopyToClipboard>
      </p>
    </div>
  );
};
