import {
  User as FirebaseUser,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import {
  DocumentData,
  DocumentReference,
  doc,
  getDoc,
  onSnapshot,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";

import { User, UserType } from "../contracts";
import { auth, db } from "../firebase";

interface UserMetadata {
  /**
   * Reference to the user's channel collection.
   */
  channel: DocumentReference;

  /**
   * Type of user.
   */
  type: UserType;

  /**
   * User's name.
   */
  displayName?: string;
}

export const login = async (email: string, password: string) => {
  return signInWithEmailAndPassword(auth, email, password);
};

export const logout = async () => {
  return signOut(auth);
};

export const getUser = async (user: FirebaseUser) => {
  const userMetadataResult = await getUserMetadata(user.uid);

  if (!userMetadataResult.exists()) {
    throw new Error(`Unable to retrieve user metadata for ${user.uid}`);
  }

  const userMetadata = transformUserMetadata(userMetadataResult.data());
  const result = transformUser(user, userMetadata);

  return result;
};

export const getUserMetadata = async (userId: string) => {
  const userDoc = doc(db, "users", userId);
  return getDoc(userDoc);
};

export const updateHeartbeat = async (userId: string) => {
  const userDoc = doc(db, "users", userId);

  return updateDoc(userDoc, {
    heartbeat: serverTimestamp(),
  });
};

export const subscribeHeartbeat = (
  /**
   * Run this callback when the subscription changes.
   */
  callback: (lastHeartbeat?: Date) => void,

  /**
   * User to subscribe to. Requires permission to read from this user.
   */
  userId: string
) => {
  const userDoc = doc(db, "users", userId);

  const unsubscribe = onSnapshot(
    userDoc,
    (doc) => {
      if (!doc.exists() || !doc.data().heartbeat) {
        callback(undefined);
        return;
      }

      const lastHeartbeat = doc.data().heartbeat.toDate();

      callback(lastHeartbeat);
    },
    (error) => {
      throw error;
    }
  );

  return unsubscribe;
};

const transformUser = (
  user: FirebaseUser,
  userMetadata: UserMetadata
): User => {
  return {
    channelId: userMetadata.channel.id,
    id: user.uid,
    name: userMetadata.displayName,
    type: userMetadata.type,
  };
};

const transformUserMetadata = (data: DocumentData): UserMetadata => {
  return {
    channel: data.channel,
    type: data.type,
    displayName: data.displayName ?? "",
  };
};
