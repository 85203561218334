export enum UserType {
  Editor = "editor",
  Viewer = "viewer",
}

export interface User {
  /**
   * ID of the user's channel.
   */
  channelId: string;
  id: string;
  name?: string;

  type: UserType;
}
