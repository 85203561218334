import * as Sentry from "@sentry/react";
import React from "react";
import ReactDOM from "react-dom/client";
import { ErrorBoundary } from "react-error-boundary";

import App from "./App.tsx";
import { UnexpectedError } from "./components/UnexpectedError.tsx";
import { AppContextProvider } from "./contexts/AppContext.tsx";
import "./firebase.ts";
import "./scss/styles.scss";
import { captureError } from "./utils";
import { EncryptionContextProvider } from "./contexts/EncryptionContext.tsx";

if (import.meta.env.PROD) {
  Sentry.init({
    dsn: "https://0bc1fb8b7cc2451c9e59ba6884b3b50f@o71499.ingest.sentry.io/4505388466438144",
    environment: import.meta.env.MODE,
  });
}

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <ErrorBoundary
      FallbackComponent={UnexpectedError}
      onError={captureError}
      onReset={() => window.location.reload()}
    >
      <EncryptionContextProvider>
        <AppContextProvider>
          <App />
        </AppContextProvider>
      </EncryptionContextProvider>
    </ErrorBoundary>
  </React.StrictMode>
);
