import { useState } from "react";
import { Button, Modal } from "react-bootstrap";

import { LinkModalContext } from "../contexts";
import { useAppContext } from "../hooks";
import { LinkForm } from "./LinkForm";

export const LinkModal = () => {
  const { linkManagementState, updateAppState } = useAppContext();
  const showingLinkForm = !!linkManagementState;
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [formDirty, setFormDirty] = useState(false);

  /**
   * Stable labels when opening/closing modal.
   * If we referenced linkManagementState.id directly, as the modal is closing the
   * labels would change because unsetting linkManagementState.id is what closes
   * the modal.
   */
  const [actionLabel, setActionLabel] = useState(
    linkManagementState?.id ? "Update" : "Add"
  );
  const [typeLabel, setTypeLabel] = useState(
    linkManagementState?.parentId ? "Sub-Link" : "Link"
  );
  const [showAllFormFields, setShowAllFormFields] = useState(
    !linkManagementState?.parentId
  );

  const handleClose = () => {
    if (
      formDirty &&
      !window.confirm("Are you sure you want to discard your changes?")
    ) {
      return;
    }

    close();
    setFormDirty(false);
  };

  const close = () => {
    updateAppState({ linkManagementState: undefined });
  };

  return (
    <LinkModalContext.Provider
      value={{
        dirty: formDirty,
        setDirty: setFormDirty,
        setSubmitting: setFormSubmitting,
        submitting: formSubmitting,
      }}
    >
      <Modal
        onEnter={() => {
          setActionLabel(linkManagementState?.id ? "Update" : "Add");
          setTypeLabel(linkManagementState?.parentId ? "Sub-Link" : "Link");
          setShowAllFormFields(!linkManagementState?.parentId);
        }}
        onHide={handleClose}
        show={showingLinkForm}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {actionLabel} {typeLabel}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <LinkForm showAllFields={showAllFormFields} />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose} variant="secondary">
            Cancel
          </Button>
          <Button
            disabled={formSubmitting}
            form="link-form"
            type="submit"
            variant="primary"
          >
            {formSubmitting ? (
              <span
                aria-hidden="true"
                className="spinner-border spinner-border-sm me-2"
                role="status"
              ></span>
            ) : null}
            {actionLabel}
          </Button>
        </Modal.Footer>
      </Modal>
    </LinkModalContext.Provider>
  );
};
