import { createContext } from "react";

export const LinkModalContext = createContext<
  | {
      dirty: boolean;
      setDirty: (newDirty: boolean) => void;
      setSubmitting: (newSubmitting: boolean) => void;
      submitting: boolean;
    }
  | undefined
>(undefined);
