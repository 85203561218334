import { Dropdown } from "react-bootstrap";
import { OrderBy, OrderDirection } from "../../../contracts";

export interface ArchivedOrderingProps {
  /**
   * Which date field to order the links by.
   */
  orderBy: OrderBy;

  /**
   * The direction to order the links by.
   */
  orderDirection: OrderDirection;

  /**
   * Callback for emitting a new field to order by.
   * @param orderBy New field to order by.
   */
  onOrderBy: (orderBy: OrderBy) => void;

  /**
   * Callback for emitting a new ordering direction.
   * @param orderDirection New ordering direction.
   */
  onOrderDirection: (orderDirection: OrderDirection) => void;
}

/**
 * Dropdown for selecting archive link ordering.
 */
export const ArchivedOrdering = ({
  orderBy,
  orderDirection,
  onOrderBy,
  onOrderDirection,
}: ArchivedOrderingProps) => {
  const orderByLabel = orderBy === "date" ? "Sent" : "Archived";
  const directionLabel = orderDirection === "asc" ? "▲" : "▼";

  const handleSelect = (newOrderBy: OrderBy) => {
    if (orderBy === newOrderBy) {
      onOrderDirection(orderDirection === "asc" ? "desc" : "asc");
    } else {
      onOrderBy(newOrderBy);
      onOrderDirection("desc");
    }
  };

  return (
    <div className="d-flex align-items-center px-3">
      <span>Sort By:</span>
      <Dropdown>
        <Dropdown.Toggle variant="link">{orderByLabel}</Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item onClick={() => handleSelect("date")}>
            Sent {orderBy === "date" ? directionLabel : null}
          </Dropdown.Item>
          <Dropdown.Item onClick={() => handleSelect("archivedAt")}>
            Archived {orderBy === "archivedAt" ? directionLabel : null}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};
