import { Button, Container, Navbar } from "react-bootstrap";
import { GearFill } from "react-bootstrap-icons";

import { AddLinkButton } from "./AddLinkButton";
import { useAppContext } from "../hooks";

export const EditorNavbar = () => {
  const { updateAppState } = useAppContext();

  const handleClickSettings = () => {
    updateAppState({ showSettings: true });
  };

  return (
    <Navbar bg="primary" sticky="top" variant="dark">
      <Container fluid>
        <Navbar.Brand>Link Friend</Navbar.Brand>

        <div className="d-flex gap-2">
          <Button onClick={handleClickSettings} variant="secondary">
            <GearFill />
          </Button>
          <AddLinkButton />
        </div>
      </Container>
    </Navbar>
  );
};
