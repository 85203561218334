import { Button } from "react-bootstrap";
import { PlusLg } from "react-bootstrap-icons";

import { useAppContext } from "../hooks";

export const AddLinkButton = () => {
  const { hasEncryptionKey, updateAppState } = useAppContext();

  const handleClickAdd = () => {
    updateAppState({
      linkManagementState: { id: undefined, parentId: undefined },
    });
  };

  return (
    <Button
      className="d-flex align-items-center gap-1"
      onClick={handleClickAdd}
      type="button"
      variant="light"
      disabled={!hasEncryptionKey}
    >
      <PlusLg className="text-primary" />
      Add
    </Button>
  );
};
