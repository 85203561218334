import { useContext } from "react";

import {
  EncryptionContext,
  EncryptionContextValue,
} from "../contexts/EncryptionContext";

export const useEncryptionContext = (): EncryptionContextValue => {
  const context = useContext(EncryptionContext);

  if (context === undefined) {
    throw new Error(
      "useEncryptionContext must be used within an EncryptionContextProvider"
    );
  }

  return context;
};
