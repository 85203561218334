import { Nav } from "react-bootstrap";
import { Link } from "wouter";
import classes from "./EditorTabs.module.scss";
import clsx from "clsx";

export const EditorTabs = () => {
  return (
    <Nav variant="tabs" activeKey="/" className={classes["editor-tabs"]}>
      <Nav.Item>
        <Link
          href="/"
          className={(active) => clsx("nav-link", active && "active")}
        >
          Live
        </Link>
      </Nav.Item>
      <Nav.Item>
        <Link
          href="/archive"
          className={(active) => clsx("nav-link", active && "active")}
        >
          Archive
        </Link>
      </Nav.Item>
    </Nav>
  );
};
