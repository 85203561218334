import { useContext } from "react";

import { LinkModalContext } from "../contexts";

export const useLinkModalContext = () => {
  const context = useContext(LinkModalContext);

  if (context === undefined) {
    throw new Error(
      "useLinkModalContext must be used within an LinkModalContext.Provider"
    );
  }

  return context;
};
