/**
 * Gets an environment variable from Vite.
 *
 * @throws Error if the variable has not been set.
 */
export const demandEnvironmentVariable = (variableName: string): string => {
  const value = import.meta.env[variableName];

  if (!value) {
    throw new Error(`Environment variable ${variableName} not set: ${value}`);
  }

  return value;
};
