import { FilePlayFill, FileWordFill, Newspaper } from "react-bootstrap-icons";
import rokuLogoUrl from "../../assets/logo-roku.png";
import { LinkType as LinkTypeType } from "../../contracts";
import classes from "./LinkType.module.scss";

export interface LinkTypeProps {
  /**
   * Link type to display.
   */
  type: LinkTypeType;
}

/**
 * Display a link's "type" as an icon.
 */
export const LinkType = ({ type }: LinkTypeProps) => {
  const TypeComponent = getComponentForType(type);

  if (!TypeComponent) {
    return null;
  }

  return (
    <div className={classes.wrapper}>
      <TypeComponent />
    </div>
  );
};

const TYPE_TO_COMPONENT = {
  [LinkTypeType.Article]: Newspaper,
  [LinkTypeType.Other]: undefined,
  [LinkTypeType.Report]: FileWordFill,
  [LinkTypeType.Roku]: () => (
    <img className={classes["roku-icon"]} src={rokuLogoUrl} alt="Roku" />
  ),
  [LinkTypeType.Video]: FilePlayFill,
};

const getComponentForType = (type: LinkTypeType) => {
  return TYPE_TO_COMPONENT[type];
};
