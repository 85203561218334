import { Route, Switch } from "wouter";
import { EditorListPage } from "../pages/EditorListPage";
import { EditorArchivePage } from "../pages/EditorArchivePage";
import { EditorNavbar } from "./EditorNavbar";
import { EditorTabs } from "./EditorTabs";
import { LinkModal } from "./LinkModal";

export const EditorApp = () => {
  return (
    <div>
      <EditorNavbar />
      <EditorTabs />
      <LinkModal />

      <Switch>
        <Route path="/archive" component={EditorArchivePage} />
        <Route component={EditorListPage} />
      </Switch>
    </div>
  );
};
