import clsx from "clsx";
import { AuthErrorCodes } from "firebase/auth";
import { FormEventHandler, useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { useErrorBoundary } from "react-error-boundary";

import { FlagType } from "../contracts";
import { login as loginService } from "../services";
import { isFirebaseError } from "../utils";
import classes from "./LoginForm.module.scss";

interface FormState {
  password: string;
  username: string;
}

/**
 * Form for logging into the app.
 */
export const LoginForm = () => {
  const [formState, setFormState] = useState<FormState>({
    password: "",
    username: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const usernameInputRef = useRef<HTMLInputElement>(null);
  const { showBoundary } = useErrorBoundary();

  // Focus the first field when showing the form.
  useEffect(() => {
    if (!usernameInputRef.current) {
      throw Error("usernameInputRef is not assigned.");
    }

    usernameInputRef.current.focus();
  }, []);

  const login = async () => {
    try {
      setLoading(true);
      await loginService(formState.username, formState.password);
    } catch (error) {
      // TODO: Move into service and abstract errors from Firebase.
      if (
        isFirebaseError(error) &&
        (error.code === AuthErrorCodes.INVALID_PASSWORD ||
          error.code === AuthErrorCodes.USER_DELETED)
      ) {
        setError("Invalid login details.");
      } else {
        showBoundary(error);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleFieldChange = (
    key: keyof FormState,
    value: FlagType | string | undefined
  ) => {
    const newFormState = {
      ...formState,
      [key]: value,
    };

    setFormState(newFormState);
  };

  const handleSubmit: FormEventHandler = (event) => {
    event.preventDefault();

    login();
  };

  return (
    <form className={clsx(classes.wrapper, "py-4")} onSubmit={handleSubmit}>
      {error ? <div className="text-danger mb-3">{error}</div> : null}

      <div className="mb-3">
        <label className="form-label" htmlFor="username">
          Username*
        </label>
        <input
          onChange={(event) =>
            handleFieldChange("username", event.target.value)
          }
          className="form-control"
          id="username"
          ref={usernameInputRef}
          required
          type="email"
          value={formState.username}
        />
      </div>

      <div className="mb-3">
        <label className="form-label" htmlFor="password">
          Password*
        </label>
        <input
          onChange={(event) =>
            handleFieldChange("password", event.target.value)
          }
          className="form-control"
          id="password"
          required
          type="password"
          value={formState.password}
        />
      </div>

      <Button disabled={loading} type="submit" variant="primary">
        {loading ? (
          <span
            aria-hidden="true"
            className="spinner-border spinner-border-sm me-2"
            role="status"
          ></span>
        ) : null}
        Login
      </Button>
    </form>
  );
};
