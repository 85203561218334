import { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useDebounce } from "react-use";
import classes from "./ArchivedSearch.module.scss";

export interface ArchivedSearchProps {
  /**
   * The current search query is controlled.
   */
  query: string;

  /**
   * Callback for emitting a new search query.
   * @param query New search query.
   */
  onSearch: (query: string) => void;
}

/**
 * Search bar for filtering the archived links.
 */
export const ArchivedSearch = ({ query, onSearch }: ArchivedSearchProps) => {
  const [inputQuery, setInputQuery] = useState("");

  // Propagate the new query after the debounce period, to avoid too much processing.
  useDebounce(
    () => {
      onSearch(inputQuery);
    },
    350,
    [inputQuery]
  );

  // Sync internal query with the external query. They are separate to support debouncing.
  useEffect(() => {
    setInputQuery(query);
  }, [query]);

  return (
    <Form.Group className={classes["archived-search"]}>
      <Form.Control
        id="query"
        type="text"
        placeholder="Search"
        value={inputQuery}
        onChange={(event) => setInputQuery(event.target.value)}
      />
      {query ? (
        <Button variant="outline-secondary" onClick={() => onSearch("")}>
          Clear
        </Button>
      ) : null}
    </Form.Group>
  );
};
