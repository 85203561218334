import { format, formatRelative } from "date-fns";
import classes from "./TimeAgo.module.scss";

export interface TimeAgoProps {
  /**
   * The date in the past to compare against (the date that was "ago").
   */
  date: Date;
}

/**
 * Display a date relative to today, without time information.
 *
 * Only "Today" and "Yesterday" are supported, all other relative dates fall back
 * to the full date. This has been determined to be the most useful for the viewer.
 *
 * @example "Today, April 4, 2024"
 * @example "Yesterday, April 4, 2024"
 * @example "April 4, 2024"
 */
export const TimeAgo = ({ date }: TimeAgoProps) => {
  let timeAgo = formatRelative(date, new Date());
  const fullFormat = format(date, "MMMM d, yyyy");

  // Remove the time portion, if it exists.
  timeAgo = timeAgo.replace(/at .*/, "").trim();

  // Everything besides "Today" and "Yesterday" should fall back to the full date.
  if (!/^(today|yesterday)$/.test(timeAgo)) {
    return <span className={classes["time-ago"]}>{fullFormat}</span>;
  }

  return (
    <span className={classes["time-ago"]}>
      {timeAgo}, {fullFormat}
    </span>
  );
};
