import { SortableLinkList } from "../components/SortableLinkList";
import classes from "./EditorListPage.module.css";

export const EditorListPage = () => {
  return (
    <div className={classes["editor-list-page"]}>
      <SortableLinkList />
    </div>
  );
};
