import clsx from "clsx";

import { useLinks } from "../../hooks";
import { Link } from "../LinkList/Link";
import { SwipeToDelete, SwipeToDeleteHandle } from "../SwipeToDelete";
import classes from "./ViewerLinkList.module.scss";
import { useRef } from "react";

/**
 * Display a list of links for the viewer.
 */
export const ViewerLinkList = () => {
  const { parentLinks: links, archiveLink } = useLinks();
  const swipeToDeleteRefs = useRef<{ [key: string]: SwipeToDeleteHandle }>({});

  const resetSwipeToDelete = (currentId: string) => {
    Object.keys(swipeToDeleteRefs.current).forEach((key) => {
      if (key !== currentId && swipeToDeleteRefs.current[key]) {
        swipeToDeleteRefs.current[key].reset();
      }
    });
  };

  return (
    <div className={clsx("list-group", classes["viewer-link-list"])}>
      {links.map(({ id }, index) => (
        <SwipeToDelete
          ref={(ref) => {
            if (ref) {
              swipeToDeleteRefs.current[id] = ref;
            } else {
              delete swipeToDeleteRefs.current[id];
            }
          }}
          key={id}
          deleteThreshold={50}
          deleteWidth={100}
          deleteText="Remove"
          onInteract={() => resetSwipeToDelete(id)}
          onDelete={() => archiveLink(id)}
        >
          <Link
            id={id}
            number={index + 1}
            /* Only the first link can trigger settings, to avoid the links at the
            bottom of the screen accidentally triggering the settings. This can
            happen when swiping a link to minimise the app, the link thinks it
            is still being pressed while the app is minimised and triggers settings. */
            sneakySettingsLink={index === 0}
          />
        </SwipeToDelete>
      ))}
    </div>
  );
};
