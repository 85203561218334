import { useEffect, useMemo, useRef, useState } from "react";
import { useAppContext } from ".";
import { LinkChange } from "../services";

const FLASH_ANIMATION_DURATION = 1500;

export const useFlashChanges = (linkId: string) => {
  const { linkChanges } = useAppContext();
  const flashTimeout = useRef<number | undefined>();
  const [changes, setChanges] = useState<LinkChange[]>([]);

  /* Hacky solution to flash a link that is updated multiple times sequentially.
  We track the applied changes internally and unset them once the flash animation
  is complete, which will remove the flash CSS class and re-apply it if another
  change comes through. */
  useEffect(() => {
    setChanges(linkChanges);

    window.clearTimeout(flashTimeout.current);
    flashTimeout.current = window.setTimeout(
      () => setChanges([]),
      FLASH_ANIMATION_DURATION
    );

    return () => {
      window.clearTimeout(flashTimeout.current);
      flashTimeout.current = undefined;
    };
  }, [linkChanges]);

  const linkHasChange = useMemo(() => {
    return changes.some((item) => item.linkId === linkId);
  }, [changes, linkId]);

  return { changes, linkHasChange };
};
