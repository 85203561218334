import { useCallback, useMemo } from "react";

import { Link, User } from "../contracts";
import * as LinkService from "../services/links";
import { isFullInterface } from "../utils";
import { useAppContext } from "./useAppContext";
import { useEncryptionContext } from ".";

export type NewLink = Omit<Link, "createdAt" | "id" | "iv">;
export type ExistingLink = Partial<Link> & { id: string };
export type SaveLinkType = ExistingLink | NewLink;

interface HookResult {
  /**
   * Confirm a link before permanent deletion.
   */
  confirmAndDeleteLink: (linkId: string) => void;

  /**
   * Delete a link. The deletion is persisted and permanent.
   */
  deleteLink: (linkId: string) => void;

  /**
   * Get a link from the app context store.
   *
   * The consumer MUST be sure the link has been loaded into the store, otherwise
   * an exception will be thrown.
   */
  getLink: (linkId: string) => Link;

  /**
   * All links from the app context store.
   */
  links: Link[];

  /**
   * Only links that have a sublink.
   */
  parentLinks: Link[];

  /**
   * Only sublinks.
   */
  sublinks: Link[];

  /**
   * Archived links that should not display for the viewer. Only parent links can
   * be archived.
   */
  archivedLinks: Link[];

  /**
   * Persist link ordering based on an array of link IDs.
   *
   * If link IDs are not provided, the current order of parent links will be used.
   */
  reorderLinks: (linkIds?: string[]) => Promise<void>;

  /**
   * Persist a new link, or existing link.
   *
   * For new links, do not set an `id` or `createdAt` property. All required properties
   * must be set except order.
   *
   * For existing links, their `id` property must be set. Any number of other properties
   * may be set.
   */
  saveLink: (link: SaveLinkType) => Promise<void>;

  /**
   * Move a live link to the archived list.
   *
   * @param linkId ID of the link to move.
   * @returns
   */
  archiveLink: (linkId: string) => Promise<void>;

  /**
   * Move an archived link back to the live list (the viewer can see).
   *
   * @param linkId ID of the link to move.
   * @returns
   */
  unarchiveLink: (linkId: string) => Promise<void>;
}

export const useLinks = () => {
  const { encrypt } = useEncryptionContext();
  const { links, user } = useAppContext();

  const parentLinks = links
    // Viewer already won't see archived links, this is just for the editor.
    .filter((link) => !link.archived)
    .filter((link) => !link.parentId);
  const sublinks = links.filter((link) => !!link.parentId);
  const archivedLinks = links
    .filter((link) => link.archived)
    .filter((link) => !link.parentId);

  const getLink = useCallback(
    (linkId: string) => {
      const link = links.find((item) => item.id === linkId);

      if (!link) {
        throw new Error(
          `Unable to retrieve invalid Link from the store: ${linkId}`
        );
      }

      return link;
    },
    [links]
  );

  const deleteLink = useCallback(
    (linkId: string) => {
      const { channelId } = assertUser(user);

      LinkService.deleteLink(linkId, channelId);
    },
    [user]
  );

  const confirmAndDeleteLink = useCallback(
    (linkId: string) => {
      const link = getLink(linkId);

      const answer = window.confirm(
        `Are you sure you want to delete "${link.title}"?`
      );

      if (answer) {
        deleteLink(linkId);
      }
    },
    [getLink, deleteLink]
  );

  const reorderLinks = useCallback(
    async (linkIds?: string[]) => {
      const { channelId } = assertUser(user);
      linkIds = linkIds ?? parentLinks.map((link) => link.id);
      const links = linkIds.map((item, idx) => ({ id: item, order: idx + 1 }));

      await LinkService.batchUpdateLinks(links, channelId, encrypt);
    },
    [user, encrypt]
  );

  const saveLink = useCallback(
    async (link: SaveLinkType) => {
      const { channelId } = assertUser(user);
      const existingLink = "id" in link && !!link.id;

      if (existingLink) {
        LinkService.updateLink(link, channelId, encrypt);
      } else if (isFullInterface<Omit<Link, "id">>(link)) {
        LinkService.addLink(link, channelId, encrypt);
      } else {
        throw new Error(
          "Unable to save invalid link. Does not match new or existing link structure."
        );
      }
    },
    [user, encrypt]
  );

  const archiveLink = useCallback(async (linkId: string) => {
    const { channelId, name } = assertUser(user);

    LinkService.archiveLink(linkId, channelId, name);
  }, []);

  const unarchiveLink = useCallback(async (linkId: string) => {
    const { channelId } = assertUser(user);

    LinkService.unarchiveLink(linkId, channelId);
  }, []);

  const result = useMemo<HookResult>(
    () => ({
      confirmAndDeleteLink,
      deleteLink,
      getLink,
      links,
      parentLinks,
      sublinks,
      archivedLinks,
      reorderLinks,
      saveLink,
      archiveLink,
      unarchiveLink,
    }),
    [
      confirmAndDeleteLink,
      deleteLink,
      getLink,
      links,
      parentLinks,
      sublinks,
      archivedLinks,
      reorderLinks,
      saveLink,
      archiveLink,
      unarchiveLink,
    ]
  );

  return result;
};

const assertUser = (user: User | undefined): User => {
  if (!user) {
    throw new Error("User required to be loaded, but it is not.");
  }

  return user;
};
