import { Button } from "react-bootstrap";
import { FallbackProps, useErrorBoundary } from "react-error-boundary";

import classes from "./UnexpectedError.module.scss";

export const UnexpectedError = ({ error }: FallbackProps) => {
  const { resetBoundary } = useErrorBoundary();
  const isProd = import.meta.env.PROD;

  return (
    <div className={classes.wrapper}>
      {isProd ? (
        <div>
          <p className="lead">
            App encountered an error. Secretary has been notified and will work
            on a fix.
          </p>
          <p className="mb-0">
            <Button onClick={resetBoundary}>Try again</Button>
          </p>
        </div>
      ) : (
        <p className="mb-0">Error: {error.message}</p>
      )}
    </div>
  );
};
