/**
 * Function to convert a hexadecimal string to a Uint8Array.
 *
 * @param hexString
 * @returns
 */
export const hexStringToUint8Array = (hexString: string) => {
  const bytes = [];
  for (let i = 0; i < hexString.length; i += 2) {
    bytes.push(parseInt(hexString.substr(i, 2), 16));
  }
  return new Uint8Array(bytes);
};
