import * as Sentry from "@sentry/react";

/**
 * Capture a handled but unexpected error to Sentry.
 *
 * @param error Error to report.
 */
export function captureError(error: unknown) {
  if (!(error instanceof Error)) {
    Sentry.captureException(new Error("Attempted to report unknown error"), {
      extra: { error },
    });

    return;
  }

  Sentry.captureException(error);
}
