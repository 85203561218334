import * as Sentry from "@sentry/react";
import { diff } from "deep-object-diff";
import { onAuthStateChanged } from "firebase/auth";
import { ReactElement, createContext, useEffect, useState } from "react";
import { useErrorBoundary } from "react-error-boundary";

import { Link, User, UserType } from "../contracts";
import { auth } from "../firebase";
import { useEncryptionContext, useFirebaseHeartbeat } from "../hooks";
import {
  LinkChange,
  getUser,
  subscribeLinks,
  logout as firebaseLogout,
} from "../services";

export enum AppStatus {
  Initialising,
  Fetching,
  Ready,
}

interface AppState {
  /**
   * State representing if the user is creating or editing a link. Also set the
   * parentId if it's a sub-link.
   */
  linkManagementState:
    | { id: string | undefined; parentId: string | undefined }
    | undefined;

  /**
   * The last applied changed to the list of links. Can be used to highlight changes
   * within the UI.
   */
  linkChanges: LinkChange[];

  /**
   * List of links shown in the app.
   */
  links: Link[];

  /**
   * Is the app initialising (checking user, etc), ready to display, etc?
   */
  status: AppStatus;

  /**
   * Currently logged in user, if there is one.
   */
  user: User | undefined;

  /**
   * Show the user settings modal.
   */
  showSettings: boolean;
}

export interface AppContextValue extends AppState {
  /**
   * Is the app in "editor" mode for editing?
   */
  editorMode: boolean;

  /**
   * Has the client set an encryption key for link titles?
   */
  hasEncryptionKey: boolean;

  /**
   * Log the currently logged in user out.
   */
  logout: () => void;

  /**
   * Update the app context state.
   *
   * @param newState Partial new state.
   */
  updateAppState: (newState: Partial<AppState>) => void;
}

export const AppContext = createContext<AppContextValue | undefined>(undefined);

const INITIAL_STATE: AppState = {
  linkManagementState: undefined,
  linkChanges: [],
  links: [],
  status: AppStatus.Initialising,
  user: undefined,
  showSettings: false,
};

export const AppContextProvider = ({
  children,
}: {
  children: ReactElement;
}) => {
  const {
    key,
    decrypt,
    reset: resetEncryptionContext,
  } = useEncryptionContext();
  const [appState, setAppState] = useState<AppState>(INITIAL_STATE);
  const { showBoundary } = useErrorBoundary();
  const { startUserHeartbeat, stopUserHeartbeat } = useFirebaseHeartbeat();

  const editorMode = appState.user?.type === UserType.Editor;
  const hasEncryptionKey = !!key;

  // Subscribe to auth state changes from Firebase.
  // TODO: Abstract into users service.
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      if (firebaseUser) {
        try {
          const user = await getUser(firebaseUser);
          updateAppState({ status: AppStatus.Fetching, user });
          Sentry.setUser({ id: user.id });

          if (user.type === UserType.Viewer) {
            startUserHeartbeat(user.id);
          }
        } catch (error) {
          showBoundary(error);
        }
      } else {
        updateAppState({ status: AppStatus.Fetching, user: undefined });
        Sentry.setUser(null);
        stopUserHeartbeat();
      }
    });

    /* No need for error handling, errors will only occur on sign-in:
    @see https://firebase.google.com/docs/reference/js/auth.md#onauthstatechanged */

    return () => unsubscribe();
  }, [showBoundary]);

  // Subscribe to Link updates from Firestore.
  useEffect(() => {
    if (!appState.user?.channelId) {
      return;
    }

    try {
      const unsubscribe = subscribeLinks(
        (newLinks, changes) => {
          updateAppState((prevState) => {
            const filteredChanges = filterChanges(
              newLinks,
              prevState.links,
              changes
            );

            return {
              linkChanges:
                // Don't show link changes on initial load.
                prevState.status < AppStatus.Ready ? [] : filteredChanges,
              links: newLinks,
              status: AppStatus.Ready,
            };
          });
        },
        appState.user.channelId,
        editorMode,
        decrypt
      );
      return () => unsubscribe();
    } catch (error) {
      showBoundary(error);
    }
  }, [appState.user?.channelId, editorMode, showBoundary, decrypt]);

  /**
   * Helper function to update the app-level state
   * @param newState New partial state to merge.
   */
  const updateAppState = (
    newState: ((prevState: AppState) => Partial<AppState>) | Partial<AppState>
  ) => {
    setAppState((prevState) => ({
      ...prevState,
      ...(typeof newState === "function" ? newState(prevState) : newState),
    }));
  };

  const logout = () => {
    updateAppState(INITIAL_STATE);
    resetEncryptionContext();
    firebaseLogout();
  };

  const contextValue: AppContextValue = {
    ...appState,
    editorMode,
    hasEncryptionKey,
    logout,
    updateAppState,
  };

  // Provide the app context and state update function to the child components
  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
};

/**
 * Filter link changes to those we're interested in highlighting in the UI.
 *
 * @param newLinks New list of links for the app.
 * @param prevLinks Previous list of links for the app.
 * @param changes Firestore source list of changes.
 * @returns
 */
const filterChanges = (
  newLinks: Link[],
  prevLinks: Link[],
  changes: LinkChange[]
) => {
  const filteredChanges = changes.filter((change) => {
    if (change.updateType === "removed") {
      return false;
    }

    if (change.updateType === "modified") {
      const currentLink = prevLinks.find((item) => item.id === change.linkId);
      const newLink = newLinks.find((item) => item.id === change.linkId);

      if (!currentLink || !newLink) {
        /* Shouldn't be possible, but not worth an exception as this result
        doesn't critically affect the app state. */
        return false;
      }

      const diffResult = diff(currentLink, newLink);
      // Don't highlight changes for these fields because they're not relevant.
      if (
        Object.keys(diffResult).filter(
          (key) =>
            // Encryption IV changes with every save, ignore.
            key !== "iv" &&
            /* We don't want to highlight ordering changes, because it'll likely
            highlight every Link that appears below the newly ordered Link because
            all those Links will have their order property changed and saved. */
            key !== "order" &&
            key !== "read" &&
            key !== "createdAt" &&
            key !== "updatedAt" &&
            key !== "archivedAt"
        ).length < 1
      ) {
        return false;
      }
    }

    return true;
  });

  return filteredChanges;
};
