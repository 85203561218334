import { Button, Modal } from "react-bootstrap";

import { useAppContext, useEncryptionContext } from "../../hooks";
import { EncryptionKeyDisplay } from "./EncryptionKeyDisplay";
import { captureError } from "../../utils";
import { config } from "../../config";

/**
 * Modal with user's settings such as account, security, etc.
 */
export const SettingsModal = () => {
  const { editorMode, showSettings, logout, updateAppState } = useAppContext();
  const { key, generateKey, importKey } = useEncryptionContext();

  const handleClose = () => {
    updateAppState({ showSettings: false });
  };

  const handleLogout = () => {
    let warning = "Are you sure you want to log out?";

    if (editorMode) {
      warning = `${warning}\n\nWARNING: If you have not stored your key, you will no longer be able to access the your data!`;
    }

    if (window.confirm(warning)) {
      logout();
    }
  };

  const handleGenerateKey = async () => {
    try {
      await generateKey();
    } catch (error) {
      console.error(error);
      captureError(error);
      alert("Failed to generate key. Please contact support.");
    }
  };

  const handleImportKey = async () => {
    const newKey = window.prompt("Paste the key.");

    if (newKey) {
      try {
        await importKey(newKey);
      } catch (error) {
        console.error(error);
        captureError(error);
        alert("Failed to import key. Please check the key and try again.");
      }
    }
  };

  const handleRefresh = () => {
    window.location.reload();
  };

  return (
    <Modal onHide={handleClose} show={showSettings}>
      <Modal.Header closeButton>
        <Modal.Title>Settings</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5>Account</h5>
        <p className="mb-0">
          <Button variant="outline-secondary" onClick={handleLogout}>
            Log out
          </Button>
        </p>

        <hr />

        <h5>Encryption Key</h5>
        {key ? (
          <EncryptionKeyDisplay
            encryptionKey={JSON.stringify(key.exportedKey)}
            addedOn={key.addedOn}
          />
        ) : (
          <div className="d-flex gap-2">
            {editorMode ? (
              <Button variant="primary" onClick={handleGenerateKey}>
                Generate key
              </Button>
            ) : null}
            <Button variant="outline-primary" onClick={handleImportKey}>
              Import key
            </Button>
          </div>
        )}

        <hr />

        <h5>Version</h5>
        <p>{config.appVersion}</p>
        <p className="mb-0">
          <Button variant="outline-secondary" onClick={handleRefresh}>
            Reload app
          </Button>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleClose} variant="secondary">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
