import { useContext } from "react";

import { AppContext, AppContextValue } from "../contexts/AppContext";

export const useAppContext = (): AppContextValue => {
  const context = useContext(AppContext);

  if (context === undefined) {
    throw new Error("useAppContext must be used within an AppContextProvider");
  }

  return context;
};
