import Fuse from "fuse.js";
import { useLinks } from "../../hooks";
import { Link } from "../LinkList/Link";

export interface ArchivedLinkListProps {
  /**
   * The search query to filter the links by.
   */
  searchQuery: string;

  /**
   * Which date field to order the links by.
   *
   * @default 'date'
   */
  orderBy?: "date" | "archivedAt";

  /**
   * The direction to order the links by.
   *
   * @default 'desc'
   */
  orderDirection?: "asc" | "desc";
}

/**
 * Display the archived links, ordered chronologically.
 */
export const ArchivedLinkList = ({
  searchQuery,
  orderBy = "date",
  orderDirection = "desc",
}: ArchivedLinkListProps) => {
  const { archivedLinks: links } = useLinks();

  /* Fuzzy search to filter the links. With basic testing, executing this on every
  render is fine. Changing search query is debounced. */
  const fuse = new Fuse(links, {
    keys: ["title", "from", "flag"],
    // We don't care how far into the title the match is.
    ignoreLocation: true,
    // We want strict matches, with only a little fuzziness allowed.
    threshold: 0.2,
  });

  const filteredLinks = searchQuery
    ? fuse.search(searchQuery).map(({ item }) => item)
    : links;

  const sortedLinks = filteredLinks.sort((a, b) => {
    // Order archived links by their order date, most recent first.
    const aTime = a[orderBy] ? a[orderBy]!.getTime() : 0;
    const bTime = b[orderBy] ? b[orderBy]!.getTime() : 0;

    return orderDirection === "desc" ? bTime - aTime : aTime - bTime;
  });

  return (
    <div className={"list-group"}>
      {sortedLinks.map(({ id }, idx) => (
        <Link
          id={id}
          key={id}
          firstInList={idx === 0}
          lastInList={idx === links.length - 1}
        />
      ))}
    </div>
  );
};
