import clsx from "clsx";
import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
  forwardRef,
} from "react";

import classes from "./SwipeToDelete.module.css";

export interface SwipeToDeleteProps {
  onDelete: Function;
  onDeleteConfirm?: Function;
  onInteract?: Function;
  deleteComponent?: React.ReactNode;
  disabled?: boolean;
  transitionDuration?: number;
  deleteWidth?: number;
  deleteThreshold?: number;
  showDeleteAction?: boolean;
  deleteColor?: string;
  deleteText?: string;
  className?: string;
  id?: string;
  rtl?: boolean;
  children?: React.ReactNode;
}

/**
 * Imperative handle for resetting the swipe to delete component.
 */
export interface SwipeToDeleteHandle {
  reset: () => void;
}

const cursorPosition = (event: any) => {
  if (event?.touches?.[0]?.clientX) return event.touches[0].clientX;
  if (event?.clientX) return event?.clientX;
  if (event?.nativeEvent?.touches?.[0]?.clientX)
    return event.nativeEvent.touches[0].clientX;
  return event?.nativeEvent?.clientX;
};

const cursorPositionY = (event: any) => {
  if (event?.touches?.[0]?.clientY) return event.touches[0].clientY;
  if (event?.clientY) return event?.clientY;
  if (event?.nativeEvent?.touches?.[0]?.clientY)
    return event.nativeEvent.touches[0].clientY;
  return event?.nativeEvent?.clientY;
};

/**
 * Forked from react-swipe-to-delete-ios to:
 *
 * 1. Remove height requirement.
 * 2. Remove deleting transition.
 *
 * @see https://github.com/arnaudambro/react-swipe-to-delete-ios
 */
export const SwipeToDelete = forwardRef<
  SwipeToDeleteHandle,
  SwipeToDeleteProps
>(
  (
    {
      onDelete,
      onDeleteConfirm,
      onInteract,
      deleteComponent,
      disabled = false,
      transitionDuration = 150,
      deleteWidth = 75,
      deleteThreshold = 75,
      showDeleteAction = true,
      deleteColor = "rgba(252, 58, 48, 1.00)",
      deleteText = "Delete",
      className = "",
      id = "",
      rtl = false,
      children,
    },
    ref
  ) => {
    const [touching, setTouching] = useState(false);
    const [translate, setTranslate] = useState(0);
    const [deleting, setDeleting] = useState(false);

    const startTouchPosition = useRef(0);
    const startTouchPositionY = useRef(0);
    const initTranslate = useRef(0);
    const container = useRef<HTMLDivElement>(null);
    const containerWidth: number =
      container.current?.getBoundingClientRect().width || 0;
    const deleteWithoutConfirmThreshold: number =
      containerWidth * (deleteThreshold / 100);

    const reset = useCallback(() => {
      setTranslate(0);
    }, []);

    const onStart = useCallback(
      (event: React.TouchEvent | React.MouseEvent) => {
        if (disabled) return;
        if (touching) return;
        startTouchPosition.current = cursorPosition(event);
        startTouchPositionY.current = cursorPositionY(event);
        initTranslate.current = translate;
        setTouching(true);
      },
      [disabled, touching, translate]
    );

    useEffect(() => {
      const root = container.current;
      root?.style.setProperty(
        "--rstdiTransitionDuration",
        transitionDuration + "ms"
      );
      root?.style.setProperty("--rstdiIsRtl", rtl ? "1" : "-1");
      root?.style.setProperty("--rstdiDeleteColor", deleteColor);
      root?.style.setProperty("--rstdiDeleteWidth", deleteWidth + "px");
    }, [deleteColor, deleteWidth, rtl, transitionDuration]);

    useEffect(() => {
      const root = container.current;
      root?.style.setProperty(
        "--rstdiTranslate",
        translate * (rtl ? -1 : 1) + "px"
      );
      const shiftDelete = -translate >= deleteWithoutConfirmThreshold;
      root?.style.setProperty(
        `--rstdiButtonMargin${rtl ? "Right" : "Left"}`,
        (shiftDelete
          ? containerWidth + translate
          : containerWidth - deleteWidth) + "px"
      );
      if (onInteract) {
        onInteract();
      }
    }, [
      translate,
      deleteWidth,
      containerWidth,
      rtl,
      deleteWithoutConfirmThreshold,
    ]);

    const onMove = useCallback(
      function (event: TouchEvent | MouseEvent) {
        if (!touching) return;
        const currentX = cursorPosition(event);
        const currentY = cursorPositionY(event);
        const deltaX = currentX - startTouchPosition.current;
        const deltaY = currentY - startTouchPositionY.current;

        // Only trigger horizontal swipe if horizontal movement is greater than vertical movement
        if (Math.abs(deltaX) > Math.abs(deltaY)) {
          if (
            !rtl &&
            currentX > startTouchPosition.current - initTranslate.current
          )
            return setTranslate(0);
          if (
            rtl &&
            currentX < startTouchPosition.current - initTranslate.current
          )
            return setTranslate(0);
          setTranslate(
            currentX - startTouchPosition.current + initTranslate.current
          );
        }
      },
      [rtl, touching, translate]
    );

    const onMouseMove = useCallback(
      function (event: MouseEvent): any {
        onMove(event);
      },
      [onMove]
    );

    const onTouchMove = useCallback(
      function (event: TouchEvent): any {
        onMove(event);
        if (translate !== 0) {
          event.preventDefault(); // Prevent vertical scrolling if swipe-to-delete is open
        }
      },
      [onMove, translate]
    );

    const onDeleteConfirmed = useCallback(() => {
      if (container.current) {
        const height = container.current?.getBoundingClientRect().height || 0;
        container.current.style.maxHeight = `${height}px`;
      }
      setDeleting(() => true);
      window.setTimeout(onDelete, transitionDuration);
    }, [onDelete, transitionDuration]);

    const onDeleteCancel = useCallback(() => {
      setTouching(() => false);
      reset();
      startTouchPosition.current = 0;
      initTranslate.current = 0;
    }, [reset]);

    const onDeleteClick = useCallback(() => {
      if (onDeleteConfirm) {
        onDeleteConfirm(onDeleteConfirmed, onDeleteCancel);
      } else {
        onDeleteConfirmed();
      }
    }, [onDeleteConfirm, onDeleteConfirmed]);

    const onMouseUp = useCallback(
      function () {
        startTouchPosition.current = 0;
        const acceptableMove = -deleteWidth * 0.7;
        const showDelete = showDeleteAction
          ? (rtl ? -1 : 1) * translate < acceptableMove
          : false;
        const notShowDelete = showDeleteAction
          ? (rtl ? -1 : 1) * translate >= acceptableMove
          : true;
        const deleteWithoutConfirm =
          (rtl ? 1 : -1) * translate >= deleteWithoutConfirmThreshold;
        if (deleteWithoutConfirm) {
          setTranslate(() => -containerWidth);
        } else if (notShowDelete) {
          setTranslate(() => 0);
        } else if (showDelete && !deleteWithoutConfirm) {
          setTranslate(() => (rtl ? 1 : -1) * deleteWidth);
        }
        setTouching(() => false);
        if (deleteWithoutConfirm) onDeleteClick();
      },
      [
        containerWidth,
        deleteWidth,
        deleteWithoutConfirmThreshold,
        onDeleteClick,
        rtl,
        translate,
      ]
    );

    useEffect(() => {
      if (touching) {
        window.addEventListener("mousemove", onMouseMove);
        window.addEventListener("touchmove", onTouchMove, { passive: false });
        window.addEventListener("mouseup", onMouseUp);
        window.addEventListener("touchend", onMouseUp);
      } else {
        window.removeEventListener("mousemove", onMouseMove);
        window.removeEventListener("touchmove", onTouchMove);
        window.removeEventListener("mouseup", onMouseUp);
        window.removeEventListener("touchend", onMouseUp);
      }
      return () => {
        window.removeEventListener("mousemove", onMouseMove);
        window.removeEventListener("touchmove", onTouchMove);
        window.removeEventListener("mouseup", onMouseUp);
        window.removeEventListener("touchend", onMouseUp);
      };
    }, [onMouseMove, onMouseUp, onTouchMove, touching]);

    useImperativeHandle(ref, () => ({
      reset,
    }));

    return (
      <div
        id={id}
        className={clsx(classes.rstdi, deleting && classes.deleting, className)}
        ref={container}
      >
        <div className={clsx(classes.delete, deleting && classes.deleting)}>
          <button onClick={onDeleteClick}>
            {deleteComponent ? deleteComponent : deleteText}
          </button>
        </div>
        <div
          className={clsx(
            classes.content,
            deleting && classes.deleting,
            !touching && classes.transition
          )}
          onMouseDown={onStart}
          onTouchStart={onStart}
        >
          {children}
        </div>
      </div>
    );
  }
);

export default SwipeToDelete;
