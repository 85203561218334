import { captureMessage } from "@sentry/react";
import { useEffect, useRef, useState } from "react";
import { subscribeHeartbeat, updateHeartbeat } from "../services";

/**
 * How often to send a heartbeat.
 */
const HEARTBEAT_INTERVAL = 60 * 1_000; // 1 minute

/**
 * How often to check if the heartbeat is still being received.
 */
const HEARTBEAT_CHECK_INTERVAL = 5 * 60 * 1_000; // 5 minutes

/**
 * If the heartbeat is not received within this time, we consider the connection
 * lost.
 */
const HEARTBEAT_THRESHOLD = 2 * 60 * 1_000; // 2 minutes

/**
 * Short delay to allow for heartbeat update when app becomes active.
 */
const HEARTBEAT_RECOVERY_DELAY = 5 * 1_000; // 5 seconds

/**
 * Custom hook sends heartbeats to Firebase at regular intervals and checks if the
 * last received heartbeat is older than a specified threshold. If the heartbeat
 * is considered "dead", the app will forcibly reload.
 */
export function useFirebaseHeartbeat() {
  const lastHeartbeat = useRef(Date.now());
  const [userId, setUserId] = useState<string | undefined>();

  function heartbeat(userId: string) {
    console.info("Sending server heartbeat.");
    updateHeartbeat(userId);
  }

  function checkHeartbeat() {
    const now = Date.now();

    console.info("Checking connection to the server.");

    if (now - lastHeartbeat.current > HEARTBEAT_THRESHOLD) {
      const lostConnectionMessage =
        "The app has lost connection to the server. Forcibly reloading.";
      console.info(lostConnectionMessage);
      captureMessage(lostConnectionMessage);
      window.location.reload();
    } else {
      console.info("Heartbeat detected.");
    }
  }

  useEffect(() => {
    if (!userId) {
      return;
    }

    const heartbeatInterval = setInterval(() => {
      heartbeat(userId);
    }, HEARTBEAT_INTERVAL);

    let checkInterval = setInterval(checkHeartbeat, HEARTBEAT_CHECK_INTERVAL);

    const unsubscribeHeartbeat = subscribeHeartbeat((newHeartbeat) => {
      if (!newHeartbeat) {
        return;
      }

      lastHeartbeat.current = newHeartbeat.getTime();
    }, userId);

    heartbeat(userId);

    /**
     * Handle visibility change to prevent false heartbeat negatives.
     * When the app is restored from the background, the async heartbeat may not
     * complete before the sync heartbeat check runs. The delay introduced here
     * ensures the heartbeat has time to complete before the check.
     */
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        heartbeat(userId);
        clearInterval(checkInterval);

        setTimeout(() => {
          checkHeartbeat();
          clearInterval(checkInterval);
          checkInterval = setInterval(checkHeartbeat, HEARTBEAT_CHECK_INTERVAL);
        }, HEARTBEAT_RECOVERY_DELAY);
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      clearInterval(heartbeatInterval);
      clearInterval(checkInterval);
      unsubscribeHeartbeat();
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [userId]);

  return {
    startUserHeartbeat: setUserId,
    // TODO: Implement.
    stopUserHeartbeat: () => {},
  };
}
