import clsx from "clsx";
import { useNetworkState } from "react-use";
import { WifiOff } from "react-bootstrap-icons";

import { useAppContext } from "../hooks";
import classes from "./OfflineStatus.module.scss";

/**
 * Display a message if the client doesn't have a network connection.
 *
 * As per https://developer.mozilla.org/en-US/docs/Web/API/Navigator/onLine, detection
 * can't easily differentiate between "has network" and "has internet". But this
 * is good enough for our simple purpose.
 */
export const OfflineStatus = () => {
  const { editorMode } = useAppContext();
  const { online } = useNetworkState();

  if (online) {
    return null;
  }

  return (
    <div
      className={clsx(classes.status, editorMode && classes["status--editor"])}
    >
      <WifiOff />
      Offline
    </div>
  );
};
