import { arrayMove } from "@dnd-kit/sortable";
import { Collapse } from "@kunukn/react-collapse";
import clsx from "clsx";
import { MouseEventHandler, useEffect, useMemo, useState } from "react";
import { DashLg, PlusLg } from "react-bootstrap-icons";
import { useErrorBoundary } from "react-error-boundary";

import { FlagType, Link } from "../../contracts";
import { useAppContext, useLinks } from "../../hooks";
import { captureError } from "../../utils";
import { LinkActions } from "./LinkActions";
import classes from "./Sublinks.module.scss";

export interface SublinksProps {
  /**
   * Sub links to display.
   */
  links: Link[];

  /**
   * The flag type of the parent link, if the parent link has one.
   */
  flag?: FlagType;

  /**
   * Show the link actions panel or not.
   *
   * @default true
   */
  showActions?: boolean;
}

/**
 * Display a list of sub links, and a button to toggle their visibility.
 *
 * If in editor mode, links are always expanded.
 */
export const Sublinks = ({ links, flag, showActions }: SublinksProps) => {
  const { editorMode } = useAppContext();
  const { saveLink, reorderLinks } = useLinks();
  const [expand, setExpand] = useState(false);
  const [collapseState, setCollapseState] = useState<string | undefined>();
  const [preventExpandAnimation, setPreventExpandAnimation] = useState(true);
  const { showBoundary } = useErrorBoundary();

  const linkIds = links.map((link) => link.id);

  // Stabilise result between renders for dependent hooks.
  const alwaysExpanded = useMemo(
    () => editorMode || links.length === 1,
    [editorMode, links.length]
  );
  useEffect(() => {
    setExpand(alwaysExpanded);
  }, [alwaysExpanded]);

  // Prevent expand transition when sublinks start already expanded.
  useEffect(() => {
    window.setTimeout(() => setPreventExpandAnimation(false), 50);
  }, []);

  const saveNewOrder = async (newOrder: string[]) => {
    try {
      await reorderLinks(newOrder);
    } catch (error) {
      showBoundary(error);
    }
  };

  const handleClickContainer: MouseEventHandler<HTMLDivElement> = (event) => {
    if (!alwaysExpanded && !expand) {
      event.preventDefault();
      setExpand(true);
    }
  };

  const handleClickLink = async ({ id, read }: Link) => {
    // Tracking "analytics", don't prevent link from being followed.

    // The viewer can change the "read" status of unread links.
    if (!editorMode && !read) {
      try {
        await saveLink({ id, read: true });
      } catch (error) {
        /* Report the error, but don't consider this operation failing to be fatal.
        The read state is useful for the reader, but not essential for the viewer. */
        captureError(error);
      }
    }
  };

  const handleCollapseChange = ({ state }: { state: string }) => {
    setCollapseState(state);
  };

  const handleMoveSublinkUp = (linkId: string) => {
    const oldIndex = linkIds.indexOf(linkId);
    const newIndex = oldIndex > 0 ? oldIndex - 1 : 0;
    const newOrder = arrayMove(linkIds, oldIndex, newIndex);
    saveNewOrder(newOrder);
  };

  const handleMoveSublinkDown = (linkId: string) => {
    const oldIndex = linkIds.indexOf(linkId);
    const newIndex =
      oldIndex < linkIds.length - 1 ? oldIndex + 1 : linkIds.length - 1;
    const newOrder = arrayMove(linkIds, oldIndex, newIndex);
    saveNewOrder(newOrder);
  };

  return (
    <div onClick={handleClickContainer}>
      <Collapse
        isOpen={expand}
        noAnim={preventExpandAnimation}
        collapseHeight="40px"
        onChange={handleCollapseChange}
      >
        <div
          className={clsx(
            classes["container"],
            preventExpandAnimation &&
              classes["container--prevent-expand-animation"],
            collapseState === "expanding" && classes["container--expanding"],
            collapseState === "expanded" && classes["container--expanded"],
            collapseState === "collapsing" && classes["container--collapsing"],
            collapseState === "collapsed" && classes["container--collapsed"],
            flag === FlagType.Important && classes["container--important"],
            flag === FlagType.ForFeedback && classes["container--for-feedback"]
          )}
        >
          <ul className={classes.sublinks}>
            {links.map((sublink, idx) => (
              <li key={sublink.id}>
                <div className="d-flex gap-3 align-items-center">
                  <a
                    href={sublink.url}
                    onClick={() => handleClickLink(sublink)}
                    // For easier debugging.
                    data-link-id={sublink.id}
                    className={clsx(
                      classes.link,
                      !sublink.read && "fw-semibold",
                      !editorMode && "link--viewer"
                    )}
                  >
                    {sublink.title}
                  </a>

                  {editorMode ? (
                    <div
                      className={clsx(
                        classes["link-actions"],
                        !showActions && "invisible"
                      )}
                    >
                      <LinkActions
                        id={sublink.id}
                        parentId={sublink.parentId}
                        disableMoveUp={idx === 0}
                        disableMoveDown={idx === links.length - 1}
                        onMoveUp={handleMoveSublinkUp}
                        onMoveDown={handleMoveSublinkDown}
                      />
                    </div>
                  ) : null}
                </div>
              </li>
            ))}
          </ul>
        </div>
      </Collapse>

      {!alwaysExpanded ? (
        <>
          {!expand ? (
            <button
              className={clsx("btn btn-link", classes["toggle-sublinks"])}
              onClick={() => setExpand(true)}
            >
              <PlusLg /> See more
            </button>
          ) : (
            <button
              className={clsx("btn btn-link", classes["toggle-sublinks"])}
              onClick={() => setExpand(false)}
            >
              <DashLg /> See less
            </button>
          )}
        </>
      ) : null}
    </div>
  );
};
