import {
  DocumentData,
  addDoc,
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  updateDoc,
  where,
  writeBatch,
} from "firebase/firestore";

import { Decrypt, Encrypt, Link } from "../contracts";
import { db } from "../firebase";

/**
 * Add a new link to the links collection under a channel.
 *
 * @param link New link without an ID.
 * @param channelId Channel to add the link to.
 */
export const addLink = async (
  link: Omit<Link, "createdAt" | "id" | "updatedAt" | "archivedAt">,
  channelId: string,
  encrypt: Encrypt
) => {
  const linksCollectionRef = buildLinksCollectionRef(channelId);
  const transformedLink = await transformFromLink(link, encrypt);

  return addDoc(linksCollectionRef, {
    ...transformedLink,
    createdAt: serverTimestamp(),
    read: false,
  });
};

export const updateLink = async (
  { id, ...link }: Partial<Link>,
  channelId: string,
  encrypt: Encrypt
) => {
  const linksCollectionRef = buildLinksCollectionRef(channelId);
  const linkDoc = doc(linksCollectionRef, id);
  const transformedLink = await transformFromLink(link, encrypt);

  return updateDoc(linkDoc, {
    ...transformedLink,
    updatedAt: serverTimestamp(),
  });
};

export const batchUpdateLinks = async (
  links: Partial<Link>[],
  channelId: string,
  encrypt: Encrypt
) => {
  const linksCollectionRef = buildLinksCollectionRef(channelId);
  const batch = writeBatch(db);

  await Promise.all(
    links.map(async (link) => {
      const linkDoc = doc(linksCollectionRef, link.id);
      const transformedLink = await transformFromLink(link, encrypt);

      batch.update(linkDoc, transformedLink);
    })
  );

  return batch.commit();
};

export interface LinkChange {
  linkId: string;
  updateType: "added" | "modified" | "removed";
}

export const subscribeLinks = (
  /**
   * Run this callback when the subscription changes.
   */
  callback: (links: Link[], changes: LinkChange[]) => void,

  /**
   * Channel to subscribe to. The user must have permission to read from this channel.
   */
  channelId: string,

  /**
   * Include archived links in the query (for the Editor).
   */
  includeArchived: boolean,

  /**
   * End-to-end decryption function.
   */
  decrypt: Decrypt
) => {
  const linksCollectionRef = buildLinksCollectionRef(channelId);
  let q = query(
    linksCollectionRef,
    orderBy("order", "asc"),
    /* "date" generally doesn't have time information (start of day) because of
    the form date selector input. To ensure newly links that have the same date
    as other newly created links appear first, we also sort on the createdAt timestamp. */
    orderBy("createdAt", "desc")
  );

  if (!includeArchived) {
    // Requires all links to have an `archived` field, even if it's false.
    q = query(q, where("archived", "!=", true));
  }

  const unsubscribe = onSnapshot(
    q,
    async (querySnapshot) => {
      const changes = querySnapshot.docChanges().map<LinkChange>((change) => ({
        linkId: change.doc.id,
        updateType: change.type,
      }));

      const results = await Promise.all(
        querySnapshot.docs.map((doc) =>
          transformToLink(doc.id, doc.data(), decrypt)
        )
      );

      callback(results, changes);
    },
    (error) => {
      throw error;
    }
  );

  return unsubscribe;
};

export const deleteLink = (id: string, channelId: string) => {
  const linksCollectionRef = buildLinksCollectionRef(channelId);
  const docRef = doc(linksCollectionRef, id);

  return deleteDoc(docRef);
};

export const archiveLink = (
  id: string,
  channelId: string,
  archivedBy?: string
) => {
  const linksCollectionRef = buildLinksCollectionRef(channelId);
  const docRef = doc(linksCollectionRef, id);

  return updateDoc(docRef, {
    archived: true,
    /* We save a static string of the user's name at the time of archiving. This
    means the name may become stale if the user changes their name, but they won't.
    A "better" solution would be to store a ref to the user record and fetch their
    name. */
    archivedBy,
    // Once archived, links no longer have an order.
    order: 0,
    archivedAt: serverTimestamp(),
    updatedAt: serverTimestamp(),
  });
};

export const unarchiveLink = (id: string, channelId: string) => {
  const linksCollectionRef = buildLinksCollectionRef(channelId);
  const docRef = doc(linksCollectionRef, id);

  return updateDoc(docRef, {
    archived: false,
    archivedBy: null,
    // Upon un-archiving, links should move to the top of the list..
    order: 0,
    archivedAt: null,
    updatedAt: serverTimestamp(),
  });
};

const buildLinksCollectionRef = (channelId: string) =>
  collection(db, `channels/${channelId}/links`);

/**
 * Transform a Firestore link entity into the contract we use in the app.
 *
 * The link title will attempt to be decrypted. If it fails, we still show the encrypted
 * link title indicating to the end user they need to add a key.
 *
 * @param id Document ID from Firestore.
 * @param data Document data from Firestore.
 * @param decrypt Decrypting function.
 * @returns App representation of a Link.
 */
const transformToLink = async (
  id: string,
  data: DocumentData,
  decrypt: Decrypt
): Promise<Link> => {
  let title = data.title;
  try {
    title = await decrypt(data.title, data.iv);
  } catch (_error) {
    // Display the encrypted title, a clear indicator a key needs to be added.
  }

  return {
    parentId: data.parentId,
    /* When creating a Link, the createdAt date isn't known until the server operation
    has completed, and won't be available for optimistic updates. The current datetime
    is fine for our current purposes. */
    createdAt: data.createdAt ? data.createdAt.toDate() : new Date(),
    date: data.date.toDate(),
    flag: data.flag,
    from: data.from,
    id,
    order: data.order,
    read: data.read,
    title,
    type: data.type,
    updatedAt: data.updatedAt ? data.updatedAt?.toDate() : undefined,
    url: data.url,
    iv: data.iv,
    archived: data.archived ?? false,
    archivedBy: data.archivedBy,
    archivedAt: data.updatedAt ? data.updatedAt?.toDate() : undefined,
  };
};

/**
 * Transform an app representation of a Link into a Firestore link entity.
 *
 * WARNING: It's not safe to construct an object with all properties and set them
 * to undefined because that tells Firestore to null the field.
 *
 * Because of this, it's only safe to transform required properties such as title,
 * URL, etc.
 *
 * @param link T
 * @param encrypt
 * @returns
 */
const transformFromLink = async (link: Partial<Link>, encrypt: Encrypt) => {
  let transformedLink = link;

  if (transformedLink.title) {
    const { encryptedPayload: title, iv } = await encrypt(
      transformedLink.title
    );
    transformedLink = { ...transformedLink, title, iv };
  }

  return transformedLink;
};
