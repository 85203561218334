import { Badge } from "react-bootstrap";
import { FlagType } from "../../contracts";
import classes from "./LinkFlagOverline.module.scss";

export interface LinkFlagProps {
  /**
   * Link flag to display.
   */
  flag: FlagType;
}

/**
 * Display a link's flags as "overline" text.
 */
export const LinkFlagOverline = ({ flag }: LinkFlagProps) => {
  const FlagComponent = getComponentForFlag(flag);

  if (!FlagComponent) {
    return null;
  }

  return <FlagComponent />;
};

const FLAG_TO_COMPONENT = {
  [FlagType.ForFeedback]: () => (
    <Badge bg="warning" text="dark" className={classes["for-feedback"]}>
      For feedback
    </Badge>
  ),
  [FlagType.Important]: () => (
    <Badge bg="danger" text="dark" className={classes.important}>
      Important
    </Badge>
  ),
};

const getComponentForFlag = (type: FlagType) => {
  return FLAG_TO_COMPONENT[type];
};
