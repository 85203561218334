/**
 * Function to convert a Uint8Array to a hexadecimal string.
 *
 * @param array
 * @returns
 */
export const uint8ArrayToHexString = (array: Uint8Array) => {
  return Array.from(array)
    .map((byte) => byte.toString(16).padStart(2, "0"))
    .join("");
};
