import { demandEnvironmentVariable } from "./utils";

interface Config {
  appVersion: string;
  firebaseApiKey: string;
  firebaseAppId: string;
  firebaseAuthDomain: string;
  firebaseMessagingSenderId: string;
  firebaseProjectId: string;
  firebaseStorageBucket: string;
}

export const config: Config = {
  appVersion: demandEnvironmentVariable("VITE_APP_VERSION"),
  firebaseApiKey: demandEnvironmentVariable("VITE_FIREBASE_API_KEY"),
  firebaseAppId: demandEnvironmentVariable("VITE_FIREBASE_APP_ID"),
  firebaseAuthDomain: demandEnvironmentVariable("VITE_FIREBASE_AUTH_DOMAIN"),
  firebaseMessagingSenderId: demandEnvironmentVariable(
    "VITE_FIREBASE_MESSAGING_SENDER_ID"
  ),
  firebaseProjectId: demandEnvironmentVariable("VITE_FIREBASE_PROJECT_ID"),
  firebaseStorageBucket: demandEnvironmentVariable(
    "VITE_FIREBASE_STORAGE_BUCKET"
  ),
};
