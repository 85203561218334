import { useState } from "react";
import { ArchivedLinkList } from "../components/ArchivedLinkList";
import { ArchivedHeader } from "../components/ArchivedHeader";
import { OrderBy, OrderDirection } from "../contracts";

export const EditorArchivePage = () => {
  /* Controlled search state lifted to the level where it can be shared between
  the search UI and the filtered list. */
  const [searchQuery, setSearchQuery] = useState("");
  const [orderBy, setOrderBy] = useState<OrderBy>("date");
  const [orderDirection, setOrderDirection] = useState<OrderDirection>("desc");

  return (
    <div>
      <ArchivedHeader
        query={searchQuery}
        orderBy={orderBy}
        orderDirection={orderDirection}
        onSearch={(query) => setSearchQuery(query)}
        onOrderBy={(orderBy) => setOrderBy(orderBy)}
        onOrderDirection={(orderDirection) => setOrderDirection(orderDirection)}
      />
      <ArchivedLinkList
        searchQuery={searchQuery}
        orderBy={orderBy}
        orderDirection={orderDirection}
      />
    </div>
  );
};
