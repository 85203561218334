import { useLongPress } from "use-long-press";
import { useAppContext } from ".";

const PRESS_DURATION_MS = 5000; // 5 seconds

export interface UseSneakySettingsOptions {
  /**
   * Press hold millisecond duration before the settings will be shown.
   *
   * @default 5000 (5 seconds)
   */
  threshold?: number;
}

/**
 * Display the settings modal after an element is pressed for a long duration.
 *
 * The intention is to hide the settings panel behind a very unlikely interaction.
 */
export const useSneakySettings = ({
  threshold = PRESS_DURATION_MS,
}: UseSneakySettingsOptions = {}) => {
  const { updateAppState } = useAppContext();

  const bind = useLongPress(
    () => {
      updateAppState({ showSettings: true });
    },
    { threshold }
  );

  return bind;
};
