/**
 * Determine if an object fully matches an interface, passed in via generic type.
 *
 * @param obj Object to be checked.
 * @returns If the object fully matches the interface or not.
 */
export const isFullInterface = <T>(obj: unknown): obj is T => {
  type InterfaceKeys = keyof T;
  type InterfaceKeyTypes = {
    [K in InterfaceKeys]: unknown;
  };

  return (
    typeof obj === "object" &&
    obj !== null &&
    Object.keys(obj).every((key) => (key as keyof InterfaceKeyTypes) in obj)
  );
};
