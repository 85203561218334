import {
  ReactElement,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Encrypt } from "../contracts";
import { hexStringToUint8Array, uint8ArrayToHexString } from "../utils";

interface EncryptionContextState {
  key?: {
    /**
     * Symmetric AES-GCM key.
     */
    key: CryptoKey;

    /**
     * JSON Web Key representation of the key, for sharing.
     * @see https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/importKey#json_web_key
     */
    exportedKey: JsonWebKey;

    /**
     * Date the key was generated or imported.
     */
    addedOn: Date;
  };
}

export interface EncryptionContextValue extends EncryptionContextState {
  /**
   * Generate a AES-GCM key, and a JWK representation for sharing.
   */
  generateKey: () => Promise<void>;

  /**
   * Import a stringified JSON Web Key (JWK) representing a AES-GCM key.
   * @see https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/importKey#json_web_key
   *
   * @param rawKey Stringified JSON Web Key.
   */
  importKey: (rawKey: string) => Promise<void>;

  /**
   * Encrypt a string payload using the currently set encryption key.
   *
   * Also generates an initialisation vector, which must be persisted along with
   * the encrypted data, and used when decrypting.
   *
   * @param payload String to encrypt.
   * @returns Encrypted string as a hexadecimal string, and the initialisation vector as a hexadecimal string.
   */
  encrypt: Encrypt;

  /**
   * Decrypt a hexadecimal string representation of Uint8Array back intro a string.
   *
   * Also requires the initialisation vector used to encrypt the string.
   *
   * @param encryptedPayload Hexadecimal string representation of Uint8Array.
   * @param iv Hexadecimal string representation of Uint8Array.
   * @returns Decrypted string.
   */
  decrypt: (encryptedPayload: string, iv: string) => Promise<string>;

  /**
   * Reset the context and clear any persisted keys.
   *
   * Be careful, if you don't have the key stored somewhere the data may no longer
   * be able to be retrieved!
   */
  reset: () => void;
}

export const EncryptionContext = createContext<
  EncryptionContextValue | undefined
>(undefined);

const INITIAL_STATE: EncryptionContextState = {
  // Will be restored later from localStorage, if previously persisted.
  key: undefined,
};

export const EncryptionContextProvider = ({
  children,
}: {
  children: ReactElement;
}) => {
  const [state, setState] = useState<EncryptionContextState>(INITIAL_STATE);

  /**
   * Helper function to update the app-level state
   * @param newState New partial state to merge.
   */
  const updateState = (
    newState:
      | ((prevState: EncryptionContextState) => Partial<EncryptionContextState>)
      | Partial<EncryptionContextState>
  ) => {
    setState((prevState) => ({
      ...prevState,
      ...(typeof newState === "function" ? newState(prevState) : newState),
    }));
  };

  const generateKey = useCallback(async () => {
    const key = await crypto.subtle.generateKey(
      {
        name: "AES-GCM",
        length: 256,
      },
      true,
      ["encrypt", "decrypt"]
    );

    const exportedKey = await crypto.subtle.exportKey("jwk", key);

    updateState({
      key: {
        key,
        exportedKey,
        addedOn: new Date(),
      },
    });
  }, []);

  const importKey = useCallback(async (rawKey: string) => {
    const exportedKey = JSON.parse(rawKey);

    const key = await crypto.subtle.importKey(
      "jwk",
      exportedKey,
      {
        name: "AES-GCM",
        length: 256,
      },
      true,
      ["encrypt", "decrypt"]
    );

    updateState({
      key: {
        key,
        exportedKey,
        addedOn: new Date(),
      },
    });
  }, []);

  const encrypt = useCallback(
    async (payload: string) => {
      if (!state.key) {
        throw new Error("Missing encryption key.");
      }

      const payloadData = new TextEncoder().encode(payload);
      const ivData = crypto.getRandomValues(new Uint8Array(12));
      const encryptedData = await crypto.subtle.encrypt(
        {
          name: "AES-GCM",
          iv: ivData,
        },
        state.key.key,
        payloadData
      );

      return {
        encryptedPayload: uint8ArrayToHexString(new Uint8Array(encryptedData)),
        iv: uint8ArrayToHexString(ivData),
      };
    },
    [state.key]
  );

  const decrypt = useCallback(
    async (encryptedPayload: string, iv: string) => {
      if (!state.key) {
        throw new Error("Missing encryption key.");
      }

      const decryptedData = await crypto.subtle.decrypt(
        {
          name: "AES-GCM",
          iv: hexStringToUint8Array(iv),
        },
        state.key.key,
        hexStringToUint8Array(encryptedPayload)
      );
      const decryptedPayload = new TextDecoder().decode(decryptedData);
      return decryptedPayload;
    },
    [state.key]
  );

  const reset = useCallback(() => {
    updateState(INITIAL_STATE);
    localStorage.removeItem("key");
  }, []);

  // Persist key.
  useEffect(() => {
    if (state.key?.exportedKey) {
      localStorage.setItem("key", JSON.stringify(state.key.exportedKey));
    }
  }, [state.key]);

  // Restore key.
  useEffect(() => {
    const storedKey = localStorage.getItem("key");
    if (storedKey) {
      importKey(storedKey);
    }
  }, [importKey]);

  const contextValue: EncryptionContextValue = useMemo(
    () => ({
      ...state,
      generateKey,
      importKey,
      encrypt,
      decrypt,
      reset,
    }),
    [decrypt, encrypt, generateKey, importKey, reset, state]
  );

  // Provide the app context and state update function to the child components
  return (
    <EncryptionContext.Provider value={contextValue}>
      {children}
    </EncryptionContext.Provider>
  );
};
