import { useLinks } from "../hooks";
import { EmptyState } from "./EmptyState";
import { ViewerLinkList } from "./ViewerLinkList";

export const ViewerApp = () => {
  const { links } = useLinks();

  if (!links.length) {
    return <EmptyState />;
  }

  return <ViewerLinkList />;
};
