import { initializeApp } from "firebase/app";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";

import { config } from "./config";

const firebaseConfig = {
  apiKey: config.firebaseApiKey,
  appId: config.firebaseAppId,
  authDomain: config.firebaseAuthDomain,
  messagingSenderId: config.firebaseMessagingSenderId,
  projectId: config.firebaseProjectId,
  storageBucket: config.firebaseStorageBucket,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase auth.
export const auth = getAuth();
if (import.meta.env.MODE === "development") {
  connectAuthEmulator(auth, "http://127.0.0.1:9099");
}

// Initialize Firestore.
export const db = getFirestore(app);
if (import.meta.env.MODE === "development") {
  connectFirestoreEmulator(db, "127.0.0.1", 8080);
}
