import { MouseEventHandler } from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import { useErrorBoundary } from "react-error-boundary";

import { useAppContext, useLinks } from "../../hooks";
import {
  Archive,
  ArrowDown,
  ArrowUp,
  PencilFill,
  PlusLg,
  TrashFill,
} from "react-bootstrap-icons";

interface LinkActionsProps {
  /**
   * ID for the link to trigger actions against.
   */
  id: string;

  /**
   * Parent Id for the link, if it's a sub-link.
   */
  parentId?: string;

  /**
   * Is the link archived?
   */
  archived?: boolean;

  /**
   * Disable the "move up" button. For example, if the link is already at the top.
   *
   * @default false
   */
  disableMoveUp?: boolean;

  /**
   * Disable the "move down" button. For example, if the link is already at the bottom.
   *
   * @default false
   */
  disableMoveDown?: boolean;

  /**
   * Callback when moving the link up.
   */
  onMoveUp?(linkId: string): void;

  /**
   * Callback when moving the link down.
   */
  onMoveDown?(linkId: string): void;
}

/**
 * Editor-only actions for updating existing links.
 */
export const LinkActions = ({
  id,
  parentId,
  archived,
  disableMoveUp = false,
  disableMoveDown = false,
  onMoveUp,
  onMoveDown,
}: LinkActionsProps) => {
  const { hasEncryptionKey, updateAppState } = useAppContext();
  const { confirmAndDeleteLink, archiveLink, unarchiveLink, reorderLinks } =
    useLinks();
  const { showBoundary } = useErrorBoundary();

  const handleClickEdit: MouseEventHandler<HTMLButtonElement> = (event) => {
    // Prevent the wrapping link from triggering.
    event.preventDefault();

    updateAppState({ linkManagementState: { id, parentId } });
  };

  const handleClickDelete: MouseEventHandler<HTMLButtonElement> = (event) => {
    // Prevent the wrapping link from triggering.
    event.preventDefault();

    try {
      confirmAndDeleteLink(id);
    } catch (error) {
      showBoundary(error);
    }
  };

  const handleClickSublink: MouseEventHandler<HTMLButtonElement> = (event) => {
    // Prevent the wrapping link from triggering.
    event.preventDefault();

    updateAppState({
      linkManagementState: { id: undefined, parentId: id },
    });
  };

  const handleClickUp: MouseEventHandler<HTMLButtonElement> = (event) => {
    // Prevent the wrapping link from triggering.
    event.preventDefault();

    if (onMoveUp) {
      onMoveUp(id);
    }
  };

  const handleClickDown: MouseEventHandler<HTMLButtonElement> = (event) => {
    // Prevent the wrapping link from triggering.
    event.preventDefault();

    if (onMoveDown) {
      onMoveDown(id);
    }
  };

  const handleClickArchive: MouseEventHandler<HTMLButtonElement> = (event) => {
    // Prevent the wrapping link from triggering.
    event.preventDefault();

    try {
      if (archived) {
        unarchiveLink(id);
        /* Un-archiving restores a link to the top of the live list by setting its
        order to 0, immediately re-order to set it to a correct order. If we didn't
        do this, un-archiving multiple links would set all of their order to 0 and
        cause unpredictable ordering. */
        reorderLinks();
      } else {
        archiveLink(id);
      }
    } catch (error) {
      showBoundary(error);
    }
  };

  return (
    <ButtonGroup size="sm" aria-label="Link actions">
      <Button
        onClick={handleClickEdit}
        type="button"
        variant="outline-primary"
        disabled={!hasEncryptionKey}
        aria-label="Edit"
        title="Edit"
      >
        <PencilFill />
      </Button>
      {!parentId ? (
        <Button
          onClick={handleClickArchive}
          type="button"
          variant="outline-primary"
          aria-label={archived ? "Un-archive" : "Archive"}
          title={archived ? "Un-archive" : "Archive"}
        >
          <Archive />
        </Button>
      ) : null}
      <Button
        onClick={handleClickDelete}
        type="button"
        variant="outline-primary"
        disabled={!hasEncryptionKey}
        aria-label="Delete"
        title="Delete"
      >
        <TrashFill />
      </Button>
      {!parentId ? (
        <Button
          onClick={handleClickSublink}
          type="button"
          variant="outline-primary"
          disabled={!hasEncryptionKey}
          aria-label="Create Sub-link"
          title="Create Sub-link"
        >
          <PlusLg />
        </Button>
      ) : (
        <>
          <Button
            onClick={handleClickUp}
            type="button"
            variant="outline-primary"
            disabled={!hasEncryptionKey || disableMoveUp}
            aria-label="Move up"
            title="Move up"
          >
            <ArrowUp />
          </Button>
          <Button
            onClick={handleClickDown}
            type="button"
            variant="outline-primary"
            disabled={!hasEncryptionKey || disableMoveDown}
            aria-label="Move down"
            title="Move down"
          >
            <ArrowDown />
          </Button>
        </>
      )}
    </ButtonGroup>
  );
};
