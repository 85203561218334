import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import clsx from "clsx";
import { GripVertical } from "react-bootstrap-icons";

import { Link, LinkProps } from "../LinkList/Link";
import classes from "./SortableLink.module.scss";

export type SortableLinkProps = LinkProps;

/**
 * Wrap a link in dnd-kit sortable.
 */
export const SortableLink = ({ id, ...linkProps }: SortableLinkProps) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div className="d-flex align-items-center" ref={setNodeRef} style={style}>
      <div
        {...attributes}
        {...listeners}
        className={clsx(classes.handle, "p-2")}
      >
        <GripVertical />
      </div>

      <Link id={id} {...linkProps} />
    </div>
  );
};
